exports.components = {
  "component---packages-themes-gatsby-theme-flexiblocks-src-pages-404-jsx": () => import("./../../../../packages/themes/gatsby-theme-flexiblocks/src/pages/404.jsx" /* webpackChunkName: "component---packages-themes-gatsby-theme-flexiblocks-src-pages-404-jsx" */),
  "component---src-pages-brugsbetingelser-index-jsx": () => import("./../../../src/pages/brugsbetingelser/index.jsx" /* webpackChunkName: "component---src-pages-brugsbetingelser-index-jsx" */),
  "component---src-pages-cookie-index-jsx": () => import("./../../../src/pages/cookie/index.jsx" /* webpackChunkName: "component---src-pages-cookie-index-jsx" */),
  "component---src-pages-funktioner-index-jsx": () => import("./../../../src/pages/funktioner/index.jsx" /* webpackChunkName: "component---src-pages-funktioner-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-om-os-index-jsx": () => import("./../../../src/pages/om-os/index.jsx" /* webpackChunkName: "component---src-pages-om-os-index-jsx" */),
  "component---src-pages-privatlivserklaering-index-jsx": () => import("./../../../src/pages/privatlivserklaering/index.jsx" /* webpackChunkName: "component---src-pages-privatlivserklaering-index-jsx" */),
  "component---src-pages-produkter-index-jsx": () => import("./../../../src/pages/produkter/index.jsx" /* webpackChunkName: "component---src-pages-produkter-index-jsx" */),
  "component---src-pages-ydelser-index-jsx": () => import("./../../../src/pages/ydelser/index.jsx" /* webpackChunkName: "component---src-pages-ydelser-index-jsx" */)
}

